import TextField from '../fields/TextField';
import SelectField from '../fields/SelectField';
import Typography from '../Typography';
import Box from '../Box';
import DocumentField from '../fields/DocumentField';
import { useMemo } from 'react';

const DocumentForm = ({ form, onSubmit, ...properties }) => {
    const options = {
        public: [
            { title: 'Backoffice', value: false },
            { title: 'Publiekelijk', value: true },
        ],
        document_types: [
            { title: 'Identificatie', value: 'government_id' },
            { title: 'KvK-uittreksel', value: 'company_registration' },
            { title: 'Contract', value: 'contract' },
            { title: 'Overig', value: 'other' },
        ],
    };

    const data = useMemo(() => form.data, [form.data]);
    const errors = useMemo(() => form.errors, [form.errors]);

    const handleFormChange = (event) => {
        const target = event.target;
        const { name, value } = target;

        form.setData({ ...data, [name]: value });
    };

    return (
        <Box as={'form'} onSubmit={onSubmit} {...properties}>
            <div className={'flex flex-col gap-2'}>
                <Typography>Zichtbaarheid*</Typography>

                <SelectField name={'public'} value={data?.public} error={errors?.public} options={options.public} onChange={handleFormChange} />

                {errors?.public !== undefined && <Typography color={'error.base'}>{errors.public}</Typography>}
            </div>

            <Box mt={24} display={'grid'} gridTemplateColumns={{ xs: '1fr', xxl: '1fr 1fr' }} gridGap={24}>
                <div className={'flex flex-col gap-2'}>
                    <Typography>Naam*</Typography>

                    <TextField name={'name'} type={'text'} value={data?.name} error={errors?.name} onChange={handleFormChange} />

                    {errors?.name !== undefined && <Typography color={'error.base'}>{errors.name}</Typography>}
                </div>

                <div className={'flex flex-col gap-2'}>
                    <Typography>Type*</Typography>

                    <SelectField name={'type'} value={data?.type} error={errors?.type} options={options.document_types} onChange={handleFormChange} />

                    {errors?.type !== undefined && <Typography color={'error.base'}>{errors.type}</Typography>}
                </div>
            </Box>

            <div className={'flex flex-col gap-2'}>
                <Typography mt={16}>Bestand*</Typography>

                <DocumentField name={'file'} value={data?.file} onChange={handleFormChange} backgroundColor={'background.variant'} />

                {errors?.file !== undefined && <Typography color={'error.base'}>{errors.file}</Typography>}
            </div>

            <input type={'submit'} hidden />
        </Box>
    );
};

export default DocumentForm;
