import Modal from '@/components/Modal';
import Typography from '@/components/Typography';
import Button from '@/components/Button';
import { useForm } from '@inertiajs/react';
import Box from '@/components/Box';
import { PencilSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';
import EntityForm from '@/components/forms/EntityForm';
import LoadingOverlay from '@/components/overlays/LoadingOverlay.jsx';

const EntityEditorModal = ({ onClose, entity, presets }) => {
    const defaultValues = { ...entity };
    const form = useForm(defaultValues);

    const handleApplicationEditor = (event) => {
        event.preventDefault();
        if (entity) {
            form.submit('put', route('settings.entities.update', { entity: entity.id }), {
                onSuccess: () => onClose(),
                preserveScroll: true,
            });
        } else {
            form.submit('post', route('settings.entities.store'), {
                onSuccess: () => onClose(),
                preserveScroll: true,
            });
        }
    };

    return (
        <Modal width={840} onClose={onClose}>
            <LoadingOverlay loading={form.processing} />

            <Typography className={'text-xl font-bold'}>Entiteit {entity ? 'wijzigen' : 'toevoegen'}</Typography>

            <Typography>Vul onderstaand de gegevens van een entiteit in.</Typography>

            <EntityForm className={'mt-5'} form={form} presets={presets} onSubmit={handleApplicationEditor} />

            <Box className={'mt-5 flex gap-5'}>
                <Button onClick={handleApplicationEditor}>
                    <PencilSquareIcon width={24} />
                    <span className={'font-bold'}>Entiteit {entity ? 'wijzigen' : 'toevoegen'}</span>
                </Button>

                <Button className={'bg-grey-150'} onClick={onClose}>
                    <XCircleIcon width={24} />
                    <span className={'font-bold'}>Sluiten</span>
                </Button>
            </Box>
        </Modal>
    );
};

export default EntityEditorModal;
